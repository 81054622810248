$( document ).ready(function() {
	$("#country-filter").addClass("expanded");
	$(".category-title").click(function(){
		if($(this).parent().hasClass("expanded")){
		  $(this).parent().removeClass("expanded");
		  $(this).next("ul").slideUp();
		} else {
		  $(this).parent().addClass("expanded");
		  $(this).next("ul").slideDown();
		}
	});

  	$(function() {
		$('#nav-menu-btn, #menu-overlay').click(function(e) {
      	e.preventDefault()
			toggleNav();
		});
	});

	var heartbeats = 0;
    window.setInterval(
	    function () {
			heartbeats++;
			if(heartbeats >= 1000){
				$(".heartbeat_count").html(">1k ");
			} else {
				$(".heartbeat_count").html(heartbeats + " ");
			}
	    }, 1400);

	$('.expand-btn').click(function(e){
		e.preventDefault();
		$(this).fadeOut();
		$(this).parents('.canvas-tile').find('.expand-box').slideToggle();
	});

	// Navigation button
	function toggleNav() {
		if ($('#site-wrapper').hasClass('show-nav')) {
			// Do things on Nav Close
			$('#site-wrapper').removeClass('show-nav');
			$('#menu-overlay').fadeOut();
			$('#nav-menu-btn i').removeClass('fa-times').addClass('fa-bars');
		} else {
			// Do things on Nav Open
			$('#menu-overlay').fadeIn();
			$('#site-wrapper').addClass('show-nav');
			$('#nav-menu-btn i').removeClass('fa-bars').addClass('fa-times');
		}
	}

	// same page anchor offset
	// The function actually applying the offset
	function offsetAnchor() {
	    if(location.hash.length !== 0) {
	        window.scrollTo(window.scrollX, window.scrollY - 75);
	    }
	}

	// This will capture hash changes while on the page
	window.addEventListener("hashchange", offsetAnchor);

	// This is here so that when you enter the page with a hash,
	// it can provide the offset in that case too. Having a timeout
	// seems necessary to allow the browser to jump to the anchor first.
	window.setTimeout(offsetAnchor, 1); // The delay of 1 is arbitrary and may not always work right (although it did in my testing).
});

var modal;
var buttons = document.getElementsByClassName('modal-button');

// Loop through all the buttons
for (var b = 0; b < buttons.length; b++) {
    // Get the button that opened the modal
    buttons[b].onclick = function (e) {
        var modalId = e.srcElement.dataset.id;

        modal = document.getElementById(modalId);

        // Open the modal
        modal.style.display = "block";

        // Get the <span> element that closes the modal
        var span = modal.getElementsByClassName("close")[0];

        // When the user clicks on the <span> (x), close the modal
        span.onclick = function() {
            modal.style.display = "none";
        }

        //Scroll to the top.
        $('html, body').animate({scrollTop:0}, 'slow');
    };
}

// When the user clicks anywhere outside of the modal, close it
window.onclick = function(event) {
    if (event.target == modal) {
        modal.style.display = none;
    }
}
